import {fetchBaseQuery} from '@reduxjs/toolkit/query'
import Cookies from 'js-cookie'
import {RootState} from '@src/store'

const BASE_URL = '/api/v1'

/**
 * Эндпоинты, для которых не нужно передавать токен авторизации и xsrf токен
 */
const IGNORE_ENDPOINTS: Array<String> = ["getAllNews", "feedbackApi"]

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, {getState, endpoint}) => {
        // By default, if we have a token in the store, let's use that for authenticated requests, exclude public endpoints
        const token: string = (getState() as RootState).user?.token
        if (token && !IGNORE_ENDPOINTS.includes(endpoint)) {
            headers.set('Authorization', `Bearer ${token}`)
            headers.set('X-XSRF-TOKEN', Cookies.get('XSRF-TOKEN'))
        }
        return headers
    },
})

export default baseQuery
