import {createTypedRoute} from "@src/modules/route";
import {lazy} from "react";
import {Outlet, RouteObject, useRoutes} from "react-router-dom";
import {PrivateOutlet} from "@src/utils/PrivateOutlet";
import {ROLE} from "@src/api";
import {constructTypedRouter} from "@src/modules/typed-router";

export const ROUTES = {
    MAIN: createTypedRoute(''),
    ABOUT: createTypedRoute('about'),
    TARIFF: createTypedRoute('tariff'),
    OUR_NETWORK: createTypedRoute('our-network'),
    SOLAR_INTERFERENCE: createTypedRoute('solar-interference'),
    FEEDBACK: createTypedRoute('feedback'),
    LOGIN: createTypedRoute('login'),
    USER: createTypedRoute('user', {
        PROFILE: createTypedRoute('profile')
    }),
    ADMIN: createTypedRoute('admin', {
        NEWS: createTypedRoute('news'),
        PAGE_EDITOR: createTypedRoute('page-editor')
    })
}

const Login = lazy(() => import('@src/pages/login/Login'));
const Profile = lazy(() => import('@src/pages/user/profile/Profile'));
const Main = lazy(() => import('@src/pages/main/Main'));
const AdminNews = lazy(() => import("@src/pages/admin/news/AdminNews"));
const About = lazy(() => import("@src/pages/about/About"));
const Tariff = lazy(() => import("@src/pages/tariff/Tariff"));
const Feedback = lazy(() => import("@src/pages/feedback/Feedback"));
const SolarInterference = lazy(() => import("@src/pages/solarInterference/SolarInterference"));
const OurNetwork = lazy(() => import("@src/pages/network/Network"));
const PageEditor = lazy(() => import("@src/pages/admin/pageeditor/PageEditor"))

const Layout = () => {
    return <Outlet/>
}

const routes: RouteObject[] = [
    {
        element: <Layout/>,
        children: [
            {
                path: ROUTES.MAIN.relativePath,
                element: <Main/>,
            },
            {
                path: ROUTES.ABOUT.relativePath,
                element: <About/>
            },
            {
                path: ROUTES.TARIFF.relativePath,
                element: <Tariff/>
            },
            {
                path: ROUTES.OUR_NETWORK.relativePath,
                element: <OurNetwork/>
            },
            {
                path: ROUTES.SOLAR_INTERFERENCE.relativePath,
                element: <SolarInterference/>
            },
            {
                path: ROUTES.FEEDBACK.relativePath,
                element: <Feedback/>
            },
            {
                path: ROUTES.LOGIN.relativePath,
                element: <Login/>
            },
            {
                path: ROUTES.USER.relativePath,
                element: <PrivateOutlet roles={[ROLE.USER]}/>,
                children: [
                    {
                        path: ROUTES.USER.PROFILE.relativePath,
                        element: <Profile/>
                    }
                ]
            },
            {
                path: ROUTES.ADMIN.relativePath,
                element: <PrivateOutlet roles={[ROLE.ADMIN]}/>,
                children: [
                    {
                        path: ROUTES.ADMIN.NEWS.relativePath,
                        element: <AdminNews/>
                    },
                    {
                        path: ROUTES.ADMIN.PAGE_EDITOR.relativePath,
                        element: <PageEditor/>
                    }
                ]
            },
        ]
    },

]

const Routes = () => {
    return useRoutes(routes)
}

const routesInstance = constructTypedRouter(ROUTES);

export const {
    useNavigate,
    Link,
    NavLink,
    Navigate,
    useMatch,
    matchPath,
    generatePath,
    useParams,
} = routesInstance

export default Routes