import {Layout as AntdLayout, Spin} from 'antd'
import {lazy, Suspense} from 'react';
import Routes from "@src/Routes";

const RestoreStateComponent = lazy(() => import('@src/components/RestoreStateComponent'));
const BaseLayout = lazy(() => import("@src/layouts/baselayout/BaseLayout"));


function App() {
    return (
        <RestoreStateComponent>
            <AntdLayout className='app'>
                <BaseLayout>
                    <Suspense fallback={<Spin/>}>
                        <Routes/>
                    </Suspense>
                </BaseLayout>
            </AntdLayout>
        </RestoreStateComponent>
    )
}

export default App
