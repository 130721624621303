import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {useUser} from '@src/hooks/useUser'
import {ROLE} from "@src/api";
import React from "react";

export type RolesType = { roles: Array<ROLE> }

export const PrivateOutlet: React.FunctionComponent<RolesType> = ({roles}) => {
    const auth = useUser()
    const location = useLocation()

    const hasPermission = (userRoles: Array<ROLE>) => userRoles?.filter(role => roles.includes(role)).length !== 0

    return auth.user.username && hasPermission(auth.user.roles) ? (
        <Outlet/>
    ) : (
        <Navigate to='/login' state={{from: location}}/>
    )
}
