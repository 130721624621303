import { Navigate as GlobalNavigate } from "react-router-dom";
import type { NavigateProps } from "react-router-dom";
import { getToWithParams } from "../utils";
import { CheckParamReq, IBaseNavigateProps } from "../types";

export type TypedNavigateProps<RoutePattern extends string> = CheckParamReq<
  Omit<NavigateProps, "to"> & IBaseNavigateProps<RoutePattern>
>;

export function Navigate<Pattern extends string>({
  to,
  params,
  ...props
}: TypedNavigateProps<Pattern>) {
  const newTo = getToWithParams({ to, params });
  return <GlobalNavigate {...props} to={newTo} />;
}
