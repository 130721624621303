import {createApi} from '@reduxjs/toolkit/query/react'
import baseFetchQuery from '@src/api/baseFetchQuery'
import {IDefaultResponse} from "@src/api/DefaultResponse";

export const enum ROLE {
    USER = 'user',
    ADMIN = 'admin'
}

export interface ITokenResponse {
    access_token: string
    refresh_token: string
    expires_in: string
    refresh_expires_in: string
    token_type: string
}

export interface IUser {
    id: string
    username: string
    email: string
    lastname: string
    firstname: string
    roles: Array<ROLE>
}

export interface ILoginRequest {
    username: string
    password: string
}

export type LoginResponse = IDefaultResponse<ITokenResponse>

export type UserInfoResponse = IDefaultResponse<IUser>

export const userApi = createApi({
    baseQuery: baseFetchQuery,
    reducerPath: 'userApi',
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, ILoginRequest>({
            query: (credentials) => ({
                url: '/login',
                method: 'POST',
                body: credentials,
            }),
        }),
        info: builder.mutation<UserInfoResponse, void>({
            query: () => '/user/info',
        }),
    }),
})

export const {useLoginMutation, useInfoMutation} = userApi
