import { shallowEqual, useSelector } from 'react-redux'
import { selectCurrentUser } from '@src/pages/login'

export const useUser = () => {
  const user = useSelector(selectCurrentUser, shallowEqual)
  
  return ({
    user
  })
}
