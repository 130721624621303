import {createApi} from '@reduxjs/toolkit/query/react'
import baseFetchQuery from '@src/api/baseFetchQuery'
import {IDefaultResponse} from "@src/api/DefaultResponse";

export const enum FeedbackCategory {
    ISSUE = "ISSUE",
    SUGGESTION = "SUGGESTION",
    ANOTHER = "ANOTHER"
}

export interface IFeedbackMessage {
    subject: string,
    category: FeedbackCategory,
    from: string,
    message: string
}

export const feedbackApi = createApi({
    baseQuery: baseFetchQuery,
    reducerPath: 'feedbackApi',
    tagTypes: ['Feedback'],
    endpoints: (builder) => ({
        sendFeedback: builder.mutation<IDefaultResponse<String>, IFeedbackMessage>({
            query: (feedback: IFeedbackMessage) => ({
                url: '/public/feedback',
                method: 'POST',
                body: feedback
            }),
            invalidatesTags: ['Feedback']
        }),
    })
})

export const {useSendFeedbackMutation} = feedbackApi
