import {createApi} from '@reduxjs/toolkit/query/react'
import baseFetchQuery from '@src/api/baseFetchQuery'
import {IDefaultResponse} from "@src/api/DefaultResponse";

export interface IPage {
    pageId: string,
    data: any
}

export const pageApi = createApi({
    baseQuery: baseFetchQuery,
    reducerPath: 'pageApi',
    tagTypes: ['Page'],
    endpoints: (builder) => ({
        loadPage: builder.query<IDefaultResponse<IPage>, String>({
            query: (pageId: string) => `/public/page/${pageId}`,
            providesTags: ['Page']
        }),
        updatePage: builder.mutation<IDefaultResponse<IPage>, IPage>({
            query: (page: IPage) => ({
                url: `/page/${page.pageId}`,
                method: 'PUT',
                body: page
            }),
            invalidatesTags: ['Page']
        }),
    })
})

export const {
    useLoadPageQuery,
    useUpdatePageMutation
} = pageApi