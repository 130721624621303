import type { ReactElement } from "react";
import { generatePath, matchPath, useMatch, useParams } from "react-router-dom";
import type { RoutePaths, RoutesConfig } from "@src/modules/route";

import type { ExtractParams, TypedPathMatch } from "./types";
import { useNavigate, TypedNavigateFunction } from "./use-navigate";
import { Navigate, TypedNavigateProps } from "./Navigate";
import { NavLink, TypedNavLinkProps } from "./NavLink";
import { Link, TypedLinkProps } from "./Link";

interface TypedRouter<TPattern extends string> {
  Link<Pattern extends TPattern>(props: TypedLinkProps<Pattern>): ReactElement;

  NavLink<Pattern extends TPattern>(
    props: TypedNavLinkProps<Pattern>
  ): ReactElement;

  Navigate<Pattern extends TPattern>(
    props: TypedNavigateProps<Pattern>
  ): ReactElement;

  useMatch<Pattern extends TPattern>(
    pattern: Pattern
  ): TypedPathMatch<Pattern, ExtractParams<Pattern>>;

  useNavigate: () => TypedNavigateFunction<TPattern>;

  matchPath<Pattern extends TPattern>(
    pattern: Pattern,
    pathname: string
  ): TypedPathMatch<Pattern, ExtractParams<Pattern>>;

  generatePath<Pattern extends TPattern>(
    path: Pattern,
    params: ExtractParams<Pattern>
  ): string;

  useParams<Pattern extends TPattern>(): Partial<ExtractParams<Pattern>>;

  // TODO: useResolvedPath

  // TODO: useLinkClickHandler

  // TODO: useHref

  // TODO: matchRoutes

  // TODO: resolvePath
}

export const constructTypedRouter = <
  TChildren,
  TPath extends string,
  TRelativePath extends string,
  T extends RoutesConfig<TChildren, TPath, TRelativePath>
>(
  ROUTES: T
) => {
  return {
    Link,
    NavLink,
    Navigate,
    useMatch: useMatch as unknown,
    matchPath: matchPath as unknown,
    useNavigate,
    generatePath,
    useParams,
  } as unknown as TypedRouter<RoutePaths<typeof ROUTES>>;
};
