import {INews} from "@src/api/news";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: Array<INews> = []

const slice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        setNews: (state, action: PayloadAction<Array<INews>>) => {
            action.payload.forEach(news => state.push(news))
        }
    }
})

export const {setNews} = slice.actions

export default slice.reducer
