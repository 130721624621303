import { generatePath } from "react-router-dom";
import { To as RRTo } from "react-router-dom";
import type { ExtractParams, To } from "../types";

interface Args<RoutePattern extends string> {
  to: To<RoutePattern>;
  params?: ExtractParams<RoutePattern>;
}

export const getToWithParams = <T extends string>({ to, params }: Args<T>) => {
  let newTo: RRTo = JSON.parse(JSON.stringify(to));

  if (typeof newTo === "string") {
    newTo = generatePath(newTo, params);
  } else if (typeof newTo.pathname === "string") {
    newTo.pathname = generatePath(newTo.pathname ?? "", params);
  }

  return newTo;
};
