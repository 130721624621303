import { Link as GlobalLink } from "react-router-dom";
import type { LinkProps } from "react-router-dom";
import { getToWithParams } from "../utils";
import { CheckParamReq, IBaseNavigateProps } from "../types";

export type TypedLinkProps<RoutePattern extends string> = CheckParamReq<
  Omit<LinkProps, "to"> & IBaseNavigateProps<RoutePattern>
>;

export function Link<Pattern extends string>({
  to,
  params,
  children,
  ...props
}: TypedLinkProps<Pattern>) {
  const newTo = getToWithParams({ to, params });
  return (
    <GlobalLink {...props} to={newTo}>
      {children}
    </GlobalLink>
  );
}
