import { NavLink as GlobalNavLink } from "react-router-dom";
import type { NavLinkProps } from "react-router-dom";
import { getToWithParams } from "../utils";
import { CheckParamReq, IBaseNavigateProps } from "../types";

export type TypedNavLinkProps<RoutePattern extends string> = CheckParamReq<
  Omit<NavLinkProps, "to"> & IBaseNavigateProps<RoutePattern>
>;

export function NavLink<Pattern extends string>({
  to,
  params,
  ...props
}: TypedNavLinkProps<Pattern>) {
  const newTo = getToWithParams({ to, params });
  return (
    <GlobalNavLink {...props} to={newTo}>
      {props.children}
    </GlobalNavLink>
  );
}
