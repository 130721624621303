export type RouteWithChildren<
  TChildren,
  TPath extends string,
  TRelativePath extends string
> = DecoratedChildren<TChildren, TPath> & Route<TPath, TRelativePath>;

// ATTENTION: НЕ МЕНЯТЬ any НА unknown - НЕ БУДЕТ РАБОТАТЬ
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RoutePaths<T> = T extends Record<string, any>
  ? {
      [K in keyof T]: K extends "to" & string
        ? T[K]
        : T[K] extends RouteWithChildren<unknown, string, string>
        ? RoutePaths<T[K]>
        : never;
    }[keyof T]
  : never;

export type RoutesConfig<
  TChildren,
  TPath extends string,
  TRelativePath extends string
> = { [key: string]: RouteWithChildren<TChildren, TPath, TRelativePath> };

type DecoratedChildren<TChildren, TPath extends string> = {
  [TKey in keyof TChildren]: TChildren[TKey] extends RouteWithChildren<
    infer TChildChildren,
    infer TChildPath,
    infer TChildRelativePath
  >
    ? RouteWithChildren<
        TChildChildren,
        TPath extends ""
          ? TChildPath
          : TChildPath extends ""
          ? TPath
          : `${TPath}/${TChildPath}`,
        TChildRelativePath
      >
    : TChildren[TKey];
};

interface Route<TPath extends string, TRelativePath extends string> {
  path: `/${PathWithoutIntermediateStars<TPath>}`;
  to: `/${To<PathWithoutIntermediateStars<TPath>>}`;
  relativePath: PathWithoutIntermediateStars<TRelativePath>;
  relativeTo: To<PathWithoutIntermediateStars<TRelativePath>>;
}

type SanitizedPath<T> = T extends `/${string}`
  ? never
  : T extends `${string}/`
  ? never
  : T;

type PathWithoutIntermediateStars<T extends string> =
  T extends `${infer TStart}*/${infer TEnd}`
    ? PathWithoutIntermediateStars<`${TStart}${TEnd}`>
    : T;

type PathWithoutFirstSlash<T extends string> = T extends `/${infer TPath}`
  ? TPath
  : T;

type To<T extends string> = T extends "*"
  ? ""
  : T extends `${infer TTo}/*`
  ? TTo
  : T;

type SanitizedChildren<T> = T extends Record<infer TKey, unknown>
  ? TKey extends string
    ? TKey extends Capitalize<TKey>
      ? T
      : never
    : T
  : T;

export const createTypedRoute = <
  TPath extends string = string,
  TChildren = void
>(
  path: SanitizedPath<TPath>,
  children?: SanitizedChildren<TChildren>
): RouteWithChildren<TChildren, TPath, TPath> => {
  return {
    ...decorateChildren(path, children),
    ...getRoute(path, path),
  } as RouteWithChildren<TChildren, TPath, TPath>;
};

function decorateChildren<TPath extends string, TChildren>(
  path: SanitizedPath<TPath>,
  children?: TChildren
): DecoratedChildren<TChildren, TPath> {
  const result: Record<string, unknown> = {};
  if (children) {
    Object.keys(children).forEach((key) => {
      const value = children[key as keyof typeof children];
      if (isRoute(value)) {
        const lastPath = removeFirstSlash(value.path);
        result[key] = {
          ...decorateChildren(path, value),
          ...getRoute(
            path === ""
              ? lastPath
              : lastPath === ""
              ? path
              : `${path}/${lastPath}`,
            value.relativePath
          ),
        };
      } else {
        result[key] = value;
      }
    });
  }

  return result as DecoratedChildren<TChildren, TPath>;
}

function getRoute<TPath extends string, TRelativePath extends string>(
  path: SanitizedPath<TPath>,
  relativePath: SanitizedPath<TRelativePath>
): Route<TPath, TRelativePath> {
  const newPathWithoutFirstSlash = removeIntermediateStars(path as TPath);
  const newRelativePath = removeIntermediateStars(
    relativePath as TRelativePath
  );
  return {
    path: `/${newPathWithoutFirstSlash}`,
    to: `/${createToFromPath(newPathWithoutFirstSlash)}`,
    relativePath: newRelativePath,
    relativeTo: createToFromPath(newRelativePath),
  };
}

function removeIntermediateStars<TPath extends string>(
  path: TPath
): PathWithoutIntermediateStars<TPath> {
  return path.replace("*/", "") as PathWithoutIntermediateStars<TPath>;
}

function removeFirstSlash<TPath extends string>(
  path: TPath
): PathWithoutFirstSlash<TPath> {
  return path.replace(/^\//, "") as PathWithoutFirstSlash<TPath>;
}

function createToFromPath<TPath extends string>(path: TPath): To<TPath> {
  if (path === "*") {
    return "" as To<TPath>;
  } else {
    return path.replace(/\/\*$/, "") as To<TPath>;
  }
}

function isRoute(
  value: unknown
): value is RouteWithChildren<unknown, string, string> {
  return Boolean(value && typeof value === "object" && "path" in value);
}
