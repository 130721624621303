import * as React from 'react'

import {Button, Col, Form, Input, Row, Typography} from 'antd';
import {Navigate, ROUTES, useNavigate} from '@src/Routes'
import {useDispatch, useSelector} from 'react-redux'
import {selectCurrentUser, setCredentials, setUser} from '../../store/slices/userSlice'
import {useInfoMutation, useLoginMutation} from '@src/api'

const {Title} = Typography;

const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [form] = Form.useForm()

    const [login, {isLoading}] = useLoginMutation()

    const [info] = useInfoMutation()

    const user = useSelector(selectCurrentUser)

    const loginCallback = async () => {
        form.validateFields()
            .then(async (val) => {
                try {
                    const response = await login(val).unwrap()

                    if (response.code === 0) {
                        localStorage.setItem('token', response.data.access_token)
                        dispatch(setCredentials(response))
                        const userInfo = await info().unwrap()
                        if (userInfo?.code === 0) {
                            dispatch(setUser(userInfo))
                        }
                        navigate(ROUTES.USER.PROFILE.to)
                    }
                } catch (err) {
                    //  do nothing
                }
            })
    }

    return user?.username ? <Navigate to='/'/> : (
        <Row justify='center' align='middle' style={{minHeight: '100%'}}>
            <Col style={{background: '#fff', padding: '24px 24px', borderRadius: 10}}>
                <Title level={2}>Вход в систему</Title>
                <Form
                    name='login'
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    initialValues={{remember: true}}
                    form={form}
                    autoComplete='off'
                >
                    <Form.Item
                        label='Username'
                        name='username'
                        rules={[{required: true, message: 'Please input your username!'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label='Password'
                        name='password'
                        rules={[{required: true, message: 'Please input your password!'}]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button loading={isLoading}
                                type='primary'
                                htmlType='submit'
                                onClick={loginCallback}>
                            Войти
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    )
}

Login.displayName = 'Login'

export default Login
