import {createApi} from '@reduxjs/toolkit/query/react'
import baseFetchQuery from '@src/api/baseFetchQuery'
import {IDefaultResponse} from "@src/api/DefaultResponse";

export const enum NewsCategory {
    INFO = "INFO",
    WARNING = "WARNING",
    DANGER = "DANGER"
}

export interface INews {
    id: number,
    title: string,
    body: string,
    newsCategory: NewsCategory,
    creationDate: Date,
    version: number
}

export const newsApi = createApi({
    baseQuery: baseFetchQuery,
    reducerPath: 'newsApi',
    tagTypes: ['News'],
    endpoints: (builder) => ({
        getAllNews: builder.query<IDefaultResponse<Array<INews>>, void>({
            query: () => '/public/news',
            providesTags: ['News']
        }),
        createNews: builder.mutation<IDefaultResponse<INews>, INews>({
            query: (news: INews) => ({
                url: '/news',
                method: 'POST',
                body: news
            }),
            invalidatesTags: ['News']
        }),
        updateNews: builder.mutation<IDefaultResponse<INews>, INews>({
            query: (news: INews) => {
                const {id, ...body} = news
                return {
                    url: `/news/${id}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: ['News'],
        }),
        deleteNews: builder.mutation<IDefaultResponse<INews>, INews>({
            query: (news: INews) => {
                const {id} = news
                return {
                    url: `/news/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['News'],
        }),
    })
})

export const {
    useGetAllNewsQuery,
    useCreateNewsMutation,
    useUpdateNewsMutation,
    useDeleteNewsMutation
} = newsApi