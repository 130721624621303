import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {IUser, LoginResponse, UserInfoResponse} from '@src/api'
import type {RootState} from '@src/store'

type UserState = IUser & {
  token?: string
}

const initialState: UserState = {
  id: "",
  username: null,
  email: null,
  lastname: null,
  firstname: null,
  roles: [],
  token: null,
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCredentials: (
      state,
      { payload: { data } }: PayloadAction<LoginResponse>,
    ) => {
      state.token = data.access_token
    },
    setUser: (state, action: PayloadAction<UserInfoResponse>) => {
      const { id, username, roles, email, firstname, lastname } = action.payload.data
      state.id = id
      state.username = username
      state.roles = roles
      state.email = email
      state.firstname = firstname
      state.lastname = lastname
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    logout: (state) => {
      state.id = initialState.id
      state.username = initialState.username
      state.roles = initialState.roles
      state.email = initialState.email
      state.firstname = initialState.firstname
      state.lastname = initialState.lastname
      state.token = null
    },
  },
})

export const { setCredentials, setUser, setToken, logout } = slice.actions

export default slice.reducer

export const selectCurrentUser = (state: RootState) => state.user as IUser
