import {
    combineReducers,
    configureStore,
    isFulfilled,
    isRejectedWithValue,
    Middleware,
    MiddlewareAPI,
    PreloadedState,
} from '@reduxjs/toolkit'
import {userApi} from '../api'
import userReducer, {logout} from './slices/userSlice'
import newsReducer from './slices/newsSlice'
import {notification} from 'antd'
import {IDefaultResponse} from "@src/api/DefaultResponse";
import {newsApi} from "@src/api/news";
import {feedbackApi} from "@src/api/feedback";
import {pageApi} from "@src/api/page";

export const rootReducer = combineReducers({
    [userApi.reducerPath]: userApi.reducer,
    [newsApi.reducerPath]: newsApi.reducer,
    [feedbackApi.reducerPath]: feedbackApi.reducer,
    [pageApi.reducerPath]: pageApi.reducer,
    user: userReducer,
    news: newsReducer
})

export const validationErrorMiddleware: Middleware =
    () => (next) => (action) => {
        const result = action.payload
        if (isFulfilled(action) && (result as IDefaultResponse<Object>)?.code === 400) {
            notification.error({message: result?.errorMessage || 'Ошибка валидации введеных полей'})
        }
        if (isRejectedWithValue(action) && (result)?.code === 500) {
            notification.error({message: result?.message || 'Ошибка сервера'})
        }
        return next(action)
    }

export const authenticationMiddleware: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (isFulfilled(action)) {
            const result = action.payload
            if ((result as IDefaultResponse<Object>)?.code === 401 || (result as IDefaultResponse<Object>)?.code === 403) {
                localStorage.removeItem('token')
                api.dispatch(logout())
                notification.error({message: result?.data?.errorMessage || 'Access denied'})
            }
        }
        // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
        if (isRejectedWithValue(action)) {
            const result = action.payload

            if (result.status === 403 || result.status === 401) {
                localStorage.removeItem('token')
                api.dispatch(logout())
                notification.error({message: result?.data?.errorMessage || 'Access denied'})
            }
        }

        return next(action)
    }

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(userApi.middleware, newsApi.middleware, feedbackApi.middleware,
                pageApi.middleware, authenticationMiddleware, validationErrorMiddleware),
        preloadedState,
        devTools: true,
    })
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];