import { useCallback } from "react";
import { useNavigate as useReactRouterNavigate } from "react-router-dom";
import type { NavigateOptions as ReactRouterNavigateOptions } from "react-router";
import type { NavigateOptions, To, ExtractParams } from "../types";
import { getToWithParams } from "../utils";

type OptionsTupleArgument<Pattern extends string> = keyof ExtractParams<
  Pattern,
  string
> extends never
  ? [Partial<ReactRouterNavigateOptions>?]
  : [NavigateOptions<Pattern>];

export interface TypedNavigateFunction<T extends string> {
  <Pattern extends T>(
    to: To<Pattern>,
    ...optionsTuple: OptionsTupleArgument<Pattern>
  ): void;
}

export function useNavigate<T extends string>() {
  const reactRouterNavigate = useReactRouterNavigate();

  return useCallback(
    (to: To<T>, options?: NavigateOptions<T>) => {
      const newTo = getToWithParams({ to, params: options?.params });

      return reactRouterNavigate(newTo, {
        state: options?.state,
        replace: options?.replace,
      });
    },
    [reactRouterNavigate]
  ) as TypedNavigateFunction<T>;
}
